import { returnErrors } from './errorAction';
import { getApiCall, postApiCall } from '../../utils/request';
import { GET_TESTS_DETAILS, TEST_REGISTER, VALIDATE_TOKEN, VALIDATE_CODE, UPLOAD_IMAGE, INTITATE_TEST, TEST_DETAILS, GET_QUESTION, SUBMIT_ANSWER, SUBMIT_FEEDBACK, TEST_STATUS_UPDATE, RUN_CODE, SUBMIT_CODE, GET_STARTER_CODE } from '../Types/types';
import axios from 'axios';

export const getTests = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`test/get-test?TestId=${id}`);
        if (response.status === 200) {
            dispatch({ type: GET_TESTS_DETAILS, payload: response.data.test })
        } else {

        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const testRegister = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`candidate/test-registration`, obj);
        if (response.status === 200) {
            returnErrors(dispatch, 'Invitation sent succesfully.Please check your email', response?.status);
            return dispatch({ type: TEST_REGISTER, payload: response.data })
        } else {

        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const validateToken = (token) => async (dispatch) => {
    try {
        const response = await getApiCall(`test/validate-test-token?token=${token}`);
        if (response.status === 200) {
            sessionStorage.setItem('token', response.data.token);
            return dispatch({ type: VALIDATE_TOKEN, payload: response.data })
        }
        else {
            returnErrors(dispatch, response?.data?.responseMessages[0]?.message, response?.status);
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
        return dispatch({ type: 'LINK_EXPIRE', })
    }
}

export const validateCode = (token, code, name, authToken) => async (dispatch) => {
    try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
        const response = await axios.get(process.env.REACT_APP_API_KEY + `test/verify-test-code?token=${token}&code=${code}&name=${name}`);
        if (response.status === 200) {
            return dispatch({ type: VALIDATE_CODE, payload: response.data })
        }
        else {
            returnErrors(dispatch, 'Something went wrong', response?.status);
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const uploadImage = (obj, testImageType, authToken) => async (dispatch) => {
    try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
        const response = await axios.post(process.env.REACT_APP_API_KEY + `test/add-test-images?testImageType=${testImageType}`, obj);
        if (response.status === 200) {
            return dispatch({ type: UPLOAD_IMAGE, payload: response.data })
        } else {
            returnErrors(dispatch, 'Something went wrong', response?.status);
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}



export const intiateTest = (token, authToken) => async (dispatch) => {
    const obj = {
        token
    }
    axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
    try {
        const response = await axios.post(process.env.REACT_APP_API_KEY + `test/start-test`, obj);
        if (response.status === 200) {
            return dispatch({ type: INTITATE_TEST, payload: response.data })
        } else {
            returnErrors(dispatch, 'Something went wrong', response?.status);
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
        return dispatch({ type: 'Test_Credit', payload: error.response?.data?.responseMessages[0]?.message })
    }
}



export const getTestSummary = (testId, authToken) => async (dispatch) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
    try {
        const response = await axios.get(process.env.REACT_APP_API_KEY + `test/get-candidate-test-details?CandidateTestId=${testId}`);
        if (response.status === 200) {
            dispatch({ type: TEST_DETAILS, payload: response.data.test })
            return dispatch({ type: TEST_DETAILS, payload: response.data.test })
        } else {
            returnErrors(dispatch, 'Something went wrong', response?.status);
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}


export const getQuestion = (token, authToken) => async (dispatch) => {
    try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;

        const response = await axios.get(process.env.REACT_APP_API_KEY + `candidate/get-candidate-test-question?Token=${token}`);
        if (response?.status === 200) {
            return dispatch({ type: GET_QUESTION, payload: response.data })
        } else {
            returnErrors(dispatch, 'Something went wrong', response?.status);
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}


export const submitAnswer = (obj, authToken) => async (dispatch) => {
    try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
        const response = await axios.post(process.env.REACT_APP_API_KEY + `test/submit-candidate-test-answer`, obj);
        if (response.status === 200) {
            return dispatch({ type: SUBMIT_ANSWER, payload: response.data })
        } else {
            returnErrors(dispatch, 'Something went wrong', response?.status);
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

export const feedbackSubmited = (obj, authToken) => async (dispatch) => {
    try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
        const response = await axios.post(process.env.REACT_APP_API_KEY + `test/add-test-feedback`, obj);
        if (response.status === 200) {
            return dispatch({ type: SUBMIT_FEEDBACK })
        } else {
            returnErrors(dispatch, 'Something went wrong', response?.status);
        }
    }
    catch (error) {
        returnErrors(dispatch, 'Something went wrong', error?.response?.status);
    }
}

export const updateTestStatus = (obj, authToken) => async (dispatch) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
    try {
        const response = await axios.post(process.env.REACT_APP_API_KEY + `candidate/update-test-status`, obj);
        if (response.status === 200) {
            return dispatch({ type: TEST_STATUS_UPDATE, payload: response.data })
        } else {
            returnErrors(dispatch, 'Something went wrong', response?.status);
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const getStarterCode = (token, TestId, testQuestionId, lang, authToken) => async (dispatch) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
    try {
        const response = await axios.get(process.env.REACT_APP_API_KEY + `codingquestion/starter-code?Token=${token}&CandidateTestId=${TestId}&CandidateTestQuestionId=${testQuestionId}&Language=${lang}`);
        console.log(response.data)
        if (response.status === 200) {
            return dispatch({ type: GET_STARTER_CODE, payload: response.data.code })
        } else {
            returnErrors(dispatch, 'Something went wrong', response?.status);
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}


export const compileCode = (obj, authToken) => async (dispatch) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
    try {
        const response = await axios.post(process.env.REACT_APP_API_KEY + `candidate/run-code`, obj);
        console.log(response.data)
        if (response.status === 200) {
            return dispatch({ type: RUN_CODE, payload: response.data.ocResponse })
        } else {
            returnErrors(dispatch, 'Something went wrong', response?.status);
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}

export const submitCode = (obj, authToken) => async (dispatch) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
    try {
        const response = await axios.post(process.env.REACT_APP_API_KEY + `candidate/submit-code`, obj);
        if (response.status === 200) {
            return dispatch({ type: SUBMIT_CODE, payload: response.data })
        } else {
            returnErrors(dispatch, 'Something went wrong', response?.status);
        }
    }
    catch (error) {
        returnErrors(dispatch, error.response?.data?.responseMessages[0]?.message, error?.response?.status);
    }
}


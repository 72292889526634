import {
  GET_USERS,
  GET_NEXT_USERS,
  NO_USER_FOUND,
  RESET_USERS_LIST,
  ADD_USERS,
  UPDATE_STATUS,
  GET_TENANTS,
  GET_NEXT_TENANTS,
  GET_SUBSCRIPTION_PLANS,
  UPDATE_SUBSCRIPTION_PLANS,
  GET_TENANTS_USER,
  UPDATE_TENANT_STATUS,
  ADD_CREDITS,
} from "../Types/types";

let intialState = {
  usersList: [],
  isUserLoading: true,
};

export default function usersReducer(state = intialState, action) {
  let stateCopy = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case GET_USERS:
      stateCopy.usersList = action.payload.users;
      stateCopy.isUserLoading = false;
      return stateCopy;
    case GET_NEXT_USERS:
      stateCopy.usersList = stateCopy.usersList.concat(action.payload.users);
      stateCopy.isUserLoading = false;
      return stateCopy;
    case NO_USER_FOUND:
      stateCopy.usersList = [];
      stateCopy.isUserLoading = false;
      return stateCopy;
    case RESET_USERS_LIST:
      stateCopy.isUserLoading = true;
      return stateCopy;
    case ADD_USERS:
      stateCopy.usersList = [...stateCopy.usersList, action.payload];
      return stateCopy;
    case UPDATE_STATUS:
      stateCopy.usersList.forEach((el) => {
        if (el.userID === action.payload.userId) {
          el.isActive = action.payload.statusId;
        }
      });
      return stateCopy;
    case GET_TENANTS:
      stateCopy.tenantUsers = action.payload.tenants;
      stateCopy.totalTenant = action.payload.totalCount;
      stateCopy.isUserLoading = false;
      return stateCopy;
    case GET_NEXT_TENANTS:
      stateCopy.tenantUsers = stateCopy.tenantUsers.concat(
        action.payload.tenants
      );
      stateCopy.totalTenant = action.payload.totalCount;
      stateCopy.isUserLoading = false;
      return stateCopy;

    // Added  Subscription Plan Use Case
    case GET_SUBSCRIPTION_PLANS:
      stateCopy.subscriptionPlanList = action.payload.subscriptions;
      return stateCopy;
    case UPDATE_SUBSCRIPTION_PLANS:
      stateCopy.isUserLoading = false;
      return stateCopy;
    case GET_TENANTS_USER:
      stateCopy.tenantIndividualUser = action.payload.tenant;
      stateCopy.isUserLoading = false;
      return stateCopy;
    case UPDATE_TENANT_STATUS:
      stateCopy.isUserLoading = false;
      return stateCopy;
    case ADD_CREDITS:
      stateCopy.isUserLoading = false;
      return stateCopy;
    default:
      return stateCopy;
  }
}

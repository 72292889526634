import React, { Fragment, useEffect, useState } from 'react';
import './testResult.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import Stepper from './stepper';
import { useDispatch, useSelector } from 'react-redux';
import { checkValidTest, getSelectedTests, updateTestName, reorderTest, removeFromSelected, getAdditionalQuestion, getNextAdditionalQuestion, saveSelectQuestion, updateTest, selectOneQuestion, removeQuestion } from '../../../Redux/ActionCreators/testLibraryAction';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AddTestModal from '../../../utils/addTestModal';
import InfiniteScroll from 'react-infinite-scroll-component';
import TestDetailDrawer from '../../../utils/testDetailDrawer';
import { useTranslation } from 'react-i18next';


export default function AddQuestion() {
    const [isOpen, setIsOpen] = useState(false);
    const [testName, setTestName] = useState('');
    const [testNameErr, setTestNameErr] = useState('');
    const [description, setDescription] = useState('');
    const [isQuestionList, setIsQuestionList] = useState(false);
    const [query, setQuery] = useState('');
    const { t } = useTranslation();
    const [questions, setQuestions] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [openDetail, setOpenDetail] = useState(false);
    const [shouldValidate, setShouldValidate] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { selectedTests, testDetails, isQuestionLoading, additionalQuestions, totalQues, selectedQuestion, deleteIds } = useSelector(state => state.testLibrary);
    console.log(location.state)
    useEffect(() => {
        if (location.state.isQuestionVisible) {
            setIsQuestionList(true);
        } else {
            setIsQuestionList(false);
            const { testId } = location.state
            dispatch(getSelectedTests(testId));
        }
    }, [])

    const goBack = () => {
        navigate('/home/select-test', {
            state: {
                testId: location.state.testId,
                next: false
            }
        })
    }


    const removeSkill = async (el) => {
        await dispatch(removeFromSelected(el));
    }



    const handleDragEnd = async (result) => {
        if (!result.destination) return
        await dispatch(reorderTest(result));
    }

    const openAdd = () => {
        setIsQuestionList(true);
        dispatch(getAdditionalQuestion(1, '', 1, 10));
    }

    const fetchData = () => {
        setPage(page + 1);
        dispatch(getNextAdditionalQuestion(1, query, page + 1, 10));
    }
    const timerRef1 = React.useRef(null);

    const searchQuestion = (val) => {
        clearTimeout(timerRef1.current);
        setPage(1);
        setQuery(val);
        timerRef1.current = setTimeout(() => delaySearch(val), 800);
    }

    const delaySearch = async (val) => {
        await dispatch(getAdditionalQuestion(1, val, page, 10));
    }

    const selectQuestion = (e, detail) => {
        dispatch(selectOneQuestion(e.target.value, e.target.checked, detail));
    }

    const selectDone = () => {
        dispatch(saveSelectQuestion(selectedQuestion));
        setIsQuestionList(false);
    }

    const backToAdd = () => {
        dispatch(saveSelectQuestion([]));
        setIsQuestionList(false);
        setPage(1);
    }

    const openCreate = () => {
        navigate('/create', { state: { edit: false, question: false, detail: null, testId: location.state.testId } })
    }

    const openAddModal = () => {
        setIsOpen(true);
        setTestName(testDetails.testName);
        setDescription(testDetails?.testDescription);
        setTestNameErr('');
        setShouldValidate(false);
    }

    useEffect(() => {
        if (!shouldValidate) return;
        const delay = setTimeout(() => {
            validateNameViaApi(testName);
        }, 500);

        return () => clearTimeout(delay);
    }, [testName, shouldValidate]);



    const validateNameViaApi = async (input) => {

        const res = input !== '' && await dispatch(checkValidTest(input));
        if (res?.payload?.isValid) {
            setTestNameErr('');
        } else {
            setTestNameErr(t('Test Name already exist'));
        }
    };


    const goToQuestion = async () => {
        setLoading(true);
        if (testName.trim('') === '') {
            setTestNameErr(t('Please Enter Test Name'));
            setLoading(false);
        }
        else if (!selectedTests?.length) {
            setTestNameErr(t('Please select Tests'));
            setLoading(false);
        }
        else {

            let obj = {
                "testName": testName,
                testDescription: description,
                testId: location.state.testId
            }
            const res = await dispatch(updateTestName(obj));

            if (res?.type === "UPDATE_TEST_NAME") {
                await setIsOpen(false);
                setTestNameErr('');
            }
        }
        setLoading(false);
    }

    const removeQues = async (el) => {
        await dispatch(removeQuestion(el));
    }

    const goToPreview = async () => {
        // if (!selectedTests?.length) {
        //     setTestNameErr('Please select Tests');
        // }
        // else {
        setLoading(true);
        let obj = {
            "testId": location.state.testId,
            "testName": testDetails?.testName,
            "tests": selectedTests?.map((el, i) => [{ testId: el.testID, levelId: el.testLevel, orderId: i + 1, testDetailId: el.testDetailId ? el.testDetailId : 0, languageId: el.languageId }][0]),
            questions: selectedQuestion?.map((el, i) => [{ questionId: el.questionID, testDetailId: el.testDetailId ? el.testDetailId : 0 }][0]),
            "deletedTestDetailIds": [...new Set(deleteIds)]
        }

        const res = await dispatch(updateTest(obj));
        if (res?.type === "UPDATE_TEST") {
            navigate(`/home/invite-preview`, { state: { testId: location.state.testId, testDetails: testDetails, myTest: false } });
        }
        setLoading(false);
    }



    return (
        <div className='gappeo-test-results'>

            {
                !isQuestionList ?
                    <Fragment>
                        <Stepper
                            type='add-question'
                            goBack={goBack}
                            goNext={goToPreview}
                        />

                        <div className='gappeo-test-lists d-flex flex-wrap'>
                            <div className='gappeo-add-question'>
                                {window.innerWidth > 767 ? null :
                                    <div className='gappeo-mobile-header'>
                                        <div className='selected-list-header d-flex justify-content-between align-items-end'>
                                            <div>
                                                <h4>{t('Assessment Summary', { ns: 'home' })}</h4>
                                                <h4 className='d-flex justify-content-start align-items-center' style={{ color: '#fff', fontSize: 18 }}>{testDetails?.testName}&nbsp;&nbsp;
                                                    <img src='/images/edit-icon.svg' alt='' className='cursor-pointer' onClick={() => openAddModal()} /></h4>
                                                <h5 >{testDetails?.testDetails?.tests?.length} {t('test', { ns: 'home' })} | {testDetails?.questionCount} {t('Qs', { ns: 'home' })} | {testDetails?.duration} {t('Mins', { ns: 'home' })}</h5>
                                                <p>{t('Level', { ns: 'home' })}: {testDetails?.testLevelName}</p>
                                            </div>
                                            <button className='outline-btn ms-4' onClick={() => setOpenDetail(true)}>{t('View details', { ns: 'home' })}</button>
                                        </div>
                                    </div>
                                }
                                <div style={{ background: '#fff', height: 500, boxShadow: '0px 1px 1px 0px #0000001a', padding: '10px 20px' }}>
                                    {/* <p >This section is optional. &nbsp;<span>SKIP</span></p> */}
                                    <div className='d-flex align-item-center'>
                                        <div className='add-question d-none'>
                                            <h3>{t('Qualifying Questions', { ns: 'home' })}</h3>
                                            <p>{t('These questions are shown before the test', { ns: 'home' })}</p>
                                            <button className='outline-btn' onClick={() => openAdd()}>{t('ADD QUESTION', { ns: 'home' })}</button>
                                            <div className='question-list'>



                                            </div>

                                        </div>
                                        {/* <div style={{ borderLeft: '1px solid #D7DADB', minHeight: 450 }}></div> */}
                                        <div className='add-question'>
                                            <h3>{t('Additional Questions', { ns: 'home' })}</h3>
                                            <p>{t('These questions are shown before the test', { ns: 'home' })}</p>
                                            <button className='outline-btn' onClick={openAdd}>{t('ADD QUESTION', { ns: 'home' })}</button>
                                            <div className='question-list'>
                                                {
                                                    selectedQuestion && selectedQuestion?.map((el, i) => {
                                                        return (
                                                            <div className='question' key={el?.questionID}>
                                                                <h4 className='elipsis-2' dangerouslySetInnerHTML={{ __html: el?.question }} />
                                                                <div className='d-flex justify-content-between aign-items-center mt-2'>
                                                                    <div>
                                                                        {el?.questionTypeName === 'Multiple Choice' ?
                                                                            <img src='/images/mcq.svg' alt='' /> :
                                                                            <img src='/images/essay.svg' alt='' width={20} />
                                                                        }
                                                                        <span> {el?.questionTypeName}</span>
                                                                    </div>
                                                                    <div>
                                                                        <img src='/images/info.svg' className='cursor-pointer' alt='' />&nbsp;&nbsp;&nbsp;
                                                                        <img src='/images/delete-icon-gray.svg' className='cursor-pointer' onClick={() => removeQues(el)} alt='' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {window.innerWidth > 767 &&
                                    <div className='next-btn'>
                                        <button disabled={loading} onClick={goToPreview} >{t('NEXT', { ns: 'home' })}</button>
                                    </div>
                                }
                            </div>
                            <div className='selected-test mt-0'>
                                <div className='selected-list-header'>
                                    <h4>{t('Assessment Summary', { ns: 'home' })}</h4>
                                    <h4 className='d-flex justify-content-center align-items-center' style={{ color: '#fff', fontSize: 18 }}>{testDetails?.testName}&nbsp;&nbsp;
                                        <img src='/images/edit-icon.svg' alt='' className='cursor-pointer' onClick={() => openAddModal()} /></h4>
                                    <h5>{testDetails?.testDetails?.tests?.length} {t('test', { ns: 'home' })} | {testDetails?.questionCount} {t('Qs', { ns: 'home' })} | {testDetails?.duration} {t('Mins', { ns: 'home' })}</h5>
                                    <p>{t('Level', { ns: 'home' })}: {testDetails?.testLevelName}</p>
                                </div>
                                <div className='selected-list'>
                                    <h1>{t('Selected Tests', { ns: 'home' })}</h1>
                                    {/* <p>{testDetails?.testDetails?.tests?.length} test | {testDetails?.questionCount} Qs | {testDetails?.duration} Mins</p> */}
                                    <DragDropContext onDragEnd={handleDragEnd}>
                                        <Droppable droppableId={'2111'}>
                                            {
                                                (provided) => (
                                                    <div  {...provided.droppableProps} ref={provided.innerRef}>
                                                        {selectedTests && selectedTests?.map((el, index) => {
                                                            return (
                                                                <Draggable key={el.testID} draggableId={el.testID.toString()} index={index} >
                                                                    {
                                                                        (provided) => (
                                                                            <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} className='selected-list-item'>
                                                                                <div className='d-flex'>
                                                                                    <img src='/images/drag.svg' alt='' width={16} />&nbsp;&nbsp;
                                                                                    <div>
                                                                                        <h1>{el.testName}</h1>
                                                                                        <p>{el.testLevelName} | {el.questionCount} Qs | {el.duration} {t('Mins', { ns: 'home' })} | {el.languageId === 1 ? 'en' : el.languageId === 2 ? 'es' : (el.languageId === 3) ? 'pt' : (el.languageId === 4) ? 'pt' : 'it'}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <img src='/images/delete-icon.svg' onClick={() => removeSkill(el)} alt='' width={16} />
                                                                            </div>
                                                                        )
                                                                    }
                                                                </Draggable>
                                                            )
                                                        })}
                                                        {provided.placeholder}
                                                    </div>

                                                )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                    :
                    <div className='gappeo-question-list'>
                        <div className='question-list-header'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <img src='/images/back.svg' alt='' className='cursor-pointer' onClick={backToAdd} />
                                <h4>{t('Add Additional Question', { ns: 'home' })} </h4>
                                <button className='done-btn' onClick={() => selectDone()}>{t('DONE', { ns: 'home' })}</button>
                            </div>
                            <div className='d-flex justify-content-between align-items-center flex-wrap mt-5'>
                                <div className="has-search"  >
                                    <span className="form-control-feedback" >
                                        <img src="/images/search.svg" alt='' />
                                    </span>
                                    <input type="search" className="form-control" value={query} placeholder={t('Search for a question title', { ns: 'home' })} onChange={(e) => searchQuestion(e.target.value)} />
                                </div>
                                <h5>{t('View Question', { ns: 'home' })}({selectedQuestion?.length})</h5>
                                <h5 style={{ color: '#009199', cursor: 'pointer' }} onClick={() => openCreate()}>{t('Add Question', { ns: 'home' })}</h5>
                            </div>
                        </div>
                        <InfiniteScroll
                            dataLength={additionalQuestions && additionalQuestions?.length} //This is important field to render the next data
                            next={totalQues > additionalQuestions?.length ? fetchData : ''}
                            hasMore={true}
                        >
                            {isQuestionLoading ?
                                'Loading..' :
                                additionalQuestions?.length > 0 ?
                                    additionalQuestions && additionalQuestions?.map((el, i) => {
                                        return (
                                            <div className='question-list1'>
                                                <div >
                                                    <input type='checkbox' style={{ width: 16, height: 16 }} value={el.questionID} checked={el.isChecked} onChange={(e) => selectQuestion(e, el)} />
                                                </div>&nbsp;&nbsp;&nbsp;
                                                <div className='elipsis-2' dangerouslySetInnerHTML={{ __html: el.question }} />


                                                <div >
                                                    {el.questionTypeName === 'Multiple Choice' ?
                                                        <img src='/images/mcq.svg' alt='' /> :
                                                        <img src='/images/essay.svg' alt='' width={20} />
                                                    }&nbsp;&nbsp;
                                                    {window.innerWidth > 767 ? null : <br />}
                                                    {el.questionTypeName}
                                                </div>
                                                {window.innerWidth > 767 && <div >
                                                    <img src='/images/info.svg' alt='' />
                                                </div>}
                                            </div>
                                        )
                                    }) :
                                    <div className='empty-list'>
                                        <img src='/images/magnifier.svg' alt='' />
                                        <div className='mt-2'>
                                            <h3>{t('No question found', { ns: 'home' })}</h3>
                                        </div>
                                    </div>
                            }
                        </InfiniteScroll>
                    </div>
            }

            {window.innerWidth < 767 &&
                <TestDetailDrawer
                    openDetail={openDetail}
                    setOpenDetail={() => setOpenDetail(false)}
                    handleDragEnd={handleDragEnd}
                    removeSkill={removeSkill}
                    selectedTests={selectedTests}
                    testDetails={testDetails}
                    selectedQuestion={selectedQuestion}
                />
            }

            <AddTestModal
                edit={true}
                isOpen={isOpen}
                setIsOpen={() => setIsOpen(false)}
                testName={testName}
                testNameErr={testNameErr}
                setTestName={(e) => {
                    setTestName(e)
                    setShouldValidate(true);
                }}
                setTestNameErr={(e) => setTestNameErr(e)}
                description={description}
                setDescription={(val) => setDescription(val)}
                goToQuestion={goToQuestion}
                loading={loading}
            />
        </div >
    )
}
